<template>
    <div>
      <v-card class="my-10 pa-0">
  
        <v-card-text width="100%" class="text-center pa-0">
              <div style=""><canvas id="canvas"  ></canvas></div> 
              <p>HoloLens2もしくはモバイル端末からログインして下さい</p>
              <v-chip
                link
                :color="!valid?'red':''"
                :class="!valid?'white--text ':''"
              >{{timerText}}</v-chip>

             
        </v-card-text>
         
        <v-card-actions>
          <v-spacer></v-spacer> 
            <v-btn class="ma-2"
              outlined
              color="narekomu"   
              @click="makeQRCode">再発行
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>

      </div>
      

</template> 

<script>

import  QRCode  from 'qrcode';
import axios from "axios";

export default {
  name: 'DialogQRCode',
  props:["name","id"],
  data () {
    return {
     QRCode:null,
     targetTime:'1000',
     timerText:"00:00",
     timerId:"",
     valid:true,    
     }
  },

  watch:{
    id: function(){
      this.makeQRCode();
    }
  },

  mounted(){
      this.makeQRCode();
  },


  methods: {

    makeQRCode: async function(){
      let qr = document.getElementById("canvas")
     
      await axios({
        method: "GET",
        url:
          `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/account/qr`,
        headers: {"X-Api-Key": "jGY68KqZR651H1iFfPwAHaYgf8pSMAeM9Qc7f4bF","SessionId": this.$store.state.auth.login.sessionId,"Content-Type":"application/json" },
        },
      ).then((response) => {
        let dic = {qrLoginKey:response.data.results.results.qrLoginKey};
        QRCode.toCanvas(qr, JSON.stringify(dic), 
        function (error) {
          if (error) console.error(error)
        })
        qr.style="width:100%; height:100%;  max-width:500px; max-height:500px;"
        this.Countdown();

      }).catch(reason => {
        console.log(reason)
      });
    },

    // close(){
    //   this.$emit("closeQRCodeEmit")
    // },

    Countdown(){
      clearInterval(this.timerId);
      this.valid = true;
      let dt = new Date();
      let target_unix = dt.getTime() + (1000 * 60 * 10 );

      this.timerId = setInterval(()=>{
        const now_unix = Math.floor(new Date()/1000);
        let sec = Math.floor(target_unix/1000)-now_unix;
        let cd_text = '';
        if(sec<=0){
          this.makeQRCode();
        }
        else{
          if(sec>=60){
            cd_text += this.td(Math.floor(sec/60))+':';
            sec = sec%60;
          }else{
            cd_text += '00';
          }
          cd_text += this.td(sec);
        }        
        this.timerText = cd_text;
      },1000);
    }
    ,

    td(x){
      if(x<10){
        return '0'+x;
      }else{
        return x;
      }
    }
 
  },
}

</script>
